[class*='home-search--'],
.home-search {
  width: 100%;
}

.home-search__logo-outer {
  position: relative;
  width: 100%;
  max-width: 240px;
  margin: 0 auto;

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: (1 / 1) * 100%;
  }
}

.home-search__logo {
  @include image-fit(contain);
}

.home-search__title {
  @extend %fw-bold;
  @extend %text-uppercase;
  @extend %text-center;
  color: $c-blue-medium;
  font-size: 4.6rem;
  line-height: 1em;
  letter-spacing: .05em;
}

.home-search__form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 540px;
  margin: 0 auto;
  border: 1px solid;
  border-color: $c-gray-light;
  padding: $spacing * 2.5 $spacing * 2;
}

.home-search__form__label {
  @extend %fw-bold;
  color: $c-blue-medium;
  font-size: 1.4rem;
  line-height: 1.2em;
}

.home-search__form__input-outer {
  display: flex;
  width: 100%;
  margin-top: $spacing / 4;
  border: 1px solid $c-blue-medium;
  padding: $spacing / 4;
  padding-left: $spacing / 2;
}

.home-search__form__input {
  flex-grow: 1;
  width: 100%;
  border: 0;
  background-color: transparent;
  color: $c-blue-medium;
  font-size: 1.6rem;
}

.home-search__form__submit {
  @extend %fw-bold;
  @extend %text-uppercase;
  margin-left: $spacing;
  border: 0;
  padding: $spacing / 2 $spacing;
  background-color: $c-blue-medium;
  color: $white;
  font-size: 1.4rem;
  line-height: 1em;
  white-space: nowrap;
}

.home-search__form__errors {
  display: block;
  margin-top: $spacing / 2;
  color: $c-error;
}

.home-search__form__disclaimer {
  margin-top: $spacing / 2;
  color: $c-gray-medium;
  font-size: 1.4rem;
  line-height: 1.2em;
}
