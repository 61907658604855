[class*='link-back--'],
.link-back {
  @extend %text-nodecoration;
  display: flex;
  align-items: center;
}

.link-back__icon-outer {
  position: relative;
  display: flex;
  overflow: hidden;
  width: 10px;
  height: 10px;
}

.link-back__icon {
  display: block;
  flex-shrink: 0;
  width: 10px;
  height: 10px;
  fill: $c-blue-medium;
  transform: rotate(180deg);
  transition: transform .2s ease-out, fill .2s ease-out;

  .link-back:hover & {
    fill: $black;
    transform: rotate(180deg) translateX(10px);
  }
}

.link-back__label {
  @extend %fw-bold;
  position: relative;
  top: .1em;
  margin-left: $spacing / 4;
  color: $c-blue-medium;
  font-size: 1.2rem;
  line-height: 1em;
  white-space: nowrap;
  letter-spacing: .05em;
  transition: color .2s ease-out;

  .link-back:hover & {
    color: $black;
  }
}

