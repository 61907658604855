// Import fonts

/* sass-lint:disable max-line-length no-duplicate-properties */
@font-face {
  font-family: 'Avenir Next LT Pro Condensed';
  src: url('./../fonts/avenir-next-lt-pro-bold-condensed.eot'); /* IE9 Compat Modes */
  src: url('./../fonts/avenir-next-lt-pro-bold-condensed.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./../fonts/avenir-next-lt-pro-bold-condensed.woff2') format('woff2'), /* Modern Browsers */
  url('./../fonts/avenir-next-lt-pro-bold-condensed.woff') format('woff'), /* Modern Browsers */
  url('./../fonts/avenir-next-lt-pro-bold-condensed.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./../fonts/avenir-next-lt-pro-bold-condensed.svg') format('svg'); /* Legacy iOS */
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'Avenir Next LT Pro Condensed';
  src: url('./../fonts/avenir-next-lt-pro-condensed.eot'); /* IE9 Compat Modes */
  src: url('./../fonts/avenir-next-lt-pro-condensed.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./../fonts/avenir-next-lt-pro-condensed.woff2') format('woff2'), /* Modern Browsers */
  url('./../fonts/avenir-next-lt-pro-condensed.woff') format('woff'), /* Modern Browsers */
  url('./../fonts/avenir-next-lt-pro-condensed.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./../fonts/avenir-next-lt-pro-condensed.svg') format('svg'); /* Legacy iOS */
  font-style: normal;
  font-weight: 400;
}
/* sass-lint:enable max-line-length no-duplicate-properties */

html {
  font-size: calc(1em * .625);
}

body {
  /* sass-lint:disable-block no-vendor-prefixes */
  font: 1.4rem / 1.5 $ff-default;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
