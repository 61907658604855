// Client
.product {
  position: relative;
  overflow: hidden;
}

.product__client {
  display: flex;
  align-items: center;
  width: 100%;
  height: 120px;
  border-bottom: 1px solid $c-gray-light;
}

.product__client__name-title {
  display: flex;
  align-items: center;
  height: 120px;
  margin: auto;
  color: $c-blue-medium;
}

.product__client__name-title h3 {
  color: $c-blue-medium;
}

.product__client__logo-outer {
  position: relative;
  width: 100px;
  margin: 0 auto;

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: (1 / 1) * 100%;
  }
}

.product__client__logo {
  @include image-fit(contain);
}

.product-inner {
  position: relative;
  min-height: calc(100vh - 120px);
  padding-bottom: $spacing * 3;
}

// Back
.product__back {
  @extend %fw-bold;

  @include mq($until: m) {
    margin: $spacing col(2) 0;
  }

  @include mq(m) {
    position: absolute;
    z-index: 2;
    top: $spacing;
    left: col(1);
  }

  @include mq(xxxl) {
    left: 0;
  }
}

// Header
.product__header {
  @extend %fw-bold;
  margin: $spacing * 2 col(2) 0;

  @include mq($until: m) {
    text-align: center;
  }

  @include mq(m) {
    width: col(10);
    margin: 0;
    margin-left: col(13);
    padding-top: $spacing * 3;
  }

  @include mq(xxxl) {
    width: col(8);
    margin-left: col(14);
  }
}

.product__header__title {
  font-size: 6.4rem;
  line-height: 1.2em;
  color: $c-blue-medium;
}

// Picture

.product__picture-outer {
  position: relative;
  width: 50%;

  @include mq($until: m) {
    width: col(20);
    margin: $spacing * 2 auto 0;
  }

  @include mq(m) {
    position: absolute;
    top: 0;
    left: col(2);
    width: col(10);
    min-height: calc(100vh - 120px);
  }

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: (1 / 1) * 100%;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: -100vw;
    width: 200vw;
    height: 100%;
    background-color: $c-gray-light;

    @include mq(m) {
      right: 0;
      width: 100vw;
    }
  }
}

.product__picture {
  @include image-fit(contain);
  z-index: 2;
  user-select: none;
  pointer-events: none;

  @include mq(m) {
    transform: translateX(-1 * col(1, 10));
  }

  @include mq(xxxl) {
    transform: translateX(-1 * col(2, 10));
  }
}

// Warranty

[class*='product__warranty--'],
.product__warranty {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: col(20);
  margin: $spacing col(2) 0;
  border: 1px solid $c-gray-light;
  padding: $spacing;
  font-size: 2rem;
  line-height: 1.3em;

  @include mq(m) {
    width: col(10);
    margin-top: $spacing * 2;
    margin-left: col(13);
  }

  @include mq(xxxl) {
    flex-direction: row;
    justify-content: space-between;
    width: col(8);
    margin-left: col(14);
  }
}

.product__warranty__text {
  @extend %fw-bold;
  @extend %text-uppercase;
  color: $c-blue-medium;

  @include mq($until: l) {
    text-align: center;
  }

  strong {
    margin-right: $spacing / 4;
  }

  span {
    @extend %fw-normal;

    [class*='product__warranty--'][class*='--under-warranty'] & {
      color: $c-under-warranty;
    }

    [class*='product__warranty--'][class*='--not-under-warranty'] & {
      color: $c-not-under-warranty;
    }
  }
}

// Specs

.product__specs {
  display: flex;
  flex-wrap: wrap;
  width: col(20);
  margin: $spacing * 3 col(2) 0;

  @include mq(m) {
    width: col(10);
    margin-left: col(13);
  }

  @include mq(xxxl) {
    width: col(8);
    margin-left: col(14);
  }
}

.product__specs__spec {
  width: calc(50% - 30px);

  &:nth-child(2n) {
    margin-left: 60px;
  }

  &:nth-child(n+3) {
    margin-top: $spacing * 1.5;
  }
}

.product__specs__spec__title {
  @extend %fw-bold;
  border-bottom: 1px solid $c-gray-light;
  padding-bottom: $spacing / 4;
  color: $c-blue-medium;
  font-size: 1.2rem;
  line-height: 1.2em;
}

.product__specs__spec__value {
  margin-top: $spacing / 2;
  color: $c-blue-medium;
  font-size: 1.4rem;
  line-height: 1.2em;
}
